<template>
  <v-container>
    <v-card width="100%" height="auto" outlined>
      <v-form id=building >
        <v-layout class="flex_mobile justify-center" >  
          <v-card outlined color="transparent" max-width="500px" class="pa-3">
          <div>{{building_code}}</div>
          
          <v-layout>
            <v-flex xs12 lg6>
              <v-select dense outlined style="height: 50px;"
              id="company" 
              ref="company" 
              label="경영체✽" 
              v-model="company"
              :items="companyItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              @change="changeCompany()"
            />
            </v-flex>

            <v-flex xs12 lg6 class="ml-1">
              <v-select dense outlined style="height: 50px;"
              id="farm" 
              ref="farm" 
              label="사업장명✽" 
              v-model="farm"
              :items="farmItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              @focus="comboFarms()"
              @change="changeFarm()"
            />
            </v-flex>
          </v-layout>

          <v-text-field dense outlined style="height:50px;"
            id="building_name" 
            ref="building_name" 
            label="건물명✽" 
            v-model="building_name" 
          />

          <v-layout>
            <v-flex xs12 lg6>
              <v-select dense outlined style="height: 50px;"
                id="building_group" 
                ref="building_group" 
                label="건물분류✽" 
                v-model="building_group"
                :items="building_groupItems"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                return-object
              />
            </v-flex>

            <v-flex xs12 lg6 class="ml-1">
              <v-text-field dense outlined  style="height: 50px;"
              id="area" 
              ref="area" 
              label="건물면적✽" 
              suffix="m²" 
              v-model="area" 
              onKeyup="this.value=this.value.replace(/[^0-9.]/g,'');"
              maxlength="7"
            />
            </v-flex>
          </v-layout>

        <div v-show="building_group.code ==='BF01'">

          <v-layout wrap>
            <v-flex xs12 lg6>
              <v-select dense outlined style="height: 50px;" 
              id="building_type" 
              ref="building_type" 
              :items="building_typeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              label="축사형태✽" 
              v-model="building_type" 
              return-object
              />
            </v-flex>

            <v-col xs12 lg6 style="height: 50px;">
              <div style="height: 0px; margin-top: -15px; letter-spacing: -1px;"> 축사정보 상세입력 </div>

                <v-radio-group dense outlined row 
                  id="detail_YN" 
                  v-model="detail_YN"
                >
                  <v-radio
                    ref="detail_y" 
                    label="Y" value="Y" color="#3a4f3f"
                  ></v-radio>
                  <v-radio
                    label="N" value="N" color="#88191a"
                  ></v-radio>
                </v-radio-group>
            </v-col> 
          </v-layout>

        <div v-show="detail_YN==='Y'">
          <v-layout>
            <v-flex xs12 lg6>
              <v-select dense outlined style="height: 50px;" 
              id="air_type" 
              ref="air_type" 
              label="환기유형" 
              v-model="air_type"
              :items="air_typeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
            </v-flex>

            <v-flex xs12 lg6 class="ml-1">
              <v-select dense outlined style="height: 50px;" 
              id="fan_type" 
              ref="fan_type" 
              label="팬유형" 
              v-model="fan_type"
              :items="fan_typeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12 lg6>
              <v-select dense outlined style="height: 50px;"
              id="air_in" 
              ref="air_in" 
              label="입기방식" 
              v-model="air_in"
              :items="air_inItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
            </v-flex>

            <v-flex xs12 lg6 class="ml-1">
              <v-select dense outlined  style="height: 50px;"
              id="air_out" 
              ref="air_out" 
              label="배기방식" 
              v-model="air_out"
              :items="air_outItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12 lg6>
              <v-select dense outlined style="height: 50px;" 
                id="roof_type" 
                ref="roof_type" 
                label="지붕소재" 
                v-model="roof_type"
                :items="roof_typeItems"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                return-object
                clearable
              />
            </v-flex>

            <v-flex xs12 lg6 class="ml-1">
              <v-select dense outlined style="height: 50px;" 
              id="roof_color" 
              ref="roof_color" 
              label="지붕색상" 
              v-model="roof_color"
              :items="roof_colorItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            >
              <template slot="selection" slot-scope="data">
                <i :class="[data.item.color, 'mr-2', 'mdi', data.item.icon]"></i>
                {{ data.item.name }}
              </template>
              <template v-slot:item="slotProps" >
                <i :class="[slotProps.item.color, 'mr-2', 'mdi', slotProps.item.icon]"></i>
                {{slotProps.item.name}}
              </template>
            </v-select>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12 lg6>
              <v-text-field dense outlined  style="height: 50px;"
              id="roof_width" 
              ref="roof_width" 
              label="지붕두께" 
              suffix="㎝" 
              v-model="roof_width" 
              onKeyup="this.value=this.value.replace(/[^0-9.]/g,'');"
              maxlength="7"
            />
            </v-flex>

            <v-flex xs12 lg6 class="ml-1">
              <v-text-field dense outlined  style="height: 50px;"
              id="wall_width" 
              ref="wall_width" 
              label="벽두께" 
              suffix="㎝" 
              v-model="wall_width" 
              onKeyup="this.value=this.value.replace(/[^0-9.]/g,'');"
              maxlength="7"
            />
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12 lg6>
              <v-select dense outlined style="height: 50px;" 
              id="side_upper_type" 
              ref="side_upper_type" 
              label="측벽상단소재" 
              v-model="side_upper_type"
              :items="side_upper_typeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
            </v-flex>

            <v-flex xs12 lg6 class="ml-1">
              <v-select dense outlined style="height: 50px;" 
              id="side_upper_color" 
              ref="side_upper_color" 
              label="측벽상단색상" 
              v-model="side_upper_color"
              :items="side_upper_colorItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            >
              <template slot="selection" slot-scope="data">
                <i :class="[data.item.color, 'mr-2', 'mdi', data.item.icon]"></i>
                {{ data.item.name }}
              </template>
              <template v-slot:item="slotProps" >
                <i :class="[slotProps.item.color, 'mr-2', 'mdi', slotProps.item.icon]"></i>
                {{slotProps.item.name}}
              </template>
            </v-select>
            </v-flex>
          </v-layout>

          <v-flex xs12 lg6>
            <v-select dense outlined style="height: 50px;" 
            id="side_lower_type" 
            ref="side_lower_type" 
            label="측벽하단소재" 
            v-model="side_lower_type"
            :items="side_lower_typeItems"
            :menu-props="{ top: false, offsetY: true }"
            item-text="name"
            return-object
            clearable
          />
          </v-flex>

          <v-layout>
            <v-flex xs12 lg6>
              <v-select dense outlined style="height: 50px;" 
              id="end_upper_type" 
              ref="end_upper_type" 
              label="끝벽상단소재" 
              v-model="end_upper_type"
              :items="end_upper_typeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
            </v-flex>

            <v-flex xs12 lg6 class="ml-1">
              <v-select dense outlined style="height: 50px;" 
                id="end_upper_color" 
                ref="end_upper_color" 
                label="끝벽상단색상" 
                v-model="end_upper_color"
                :items="end_upper_colorItems"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                return-object
                clearable
                >
                <template slot="selection" slot-scope="data">
                  <i :class="[data.item.color, 'mr-2', 'mdi', data.item.icon]"></i>
                  {{ data.item.name }}
                </template>
                <template v-slot:item="slotProps" >
                  <i :class="[slotProps.item.color, 'mr-2', 'mdi', slotProps.item.icon]"></i>
                  {{slotProps.item.name}}
                </template>
              </v-select>
            </v-flex>
          </v-layout>

          <v-flex xs12 lg6>
            <v-select dense outlined style="height: 50px;"
              id="end_lower_type" 
              ref="end_lower_type" 
              label="끝벽하단소재" 
              v-model="end_lower_type"
              :items="end_lower_typeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
          </v-flex>

          <v-layout>
            <v-flex xs12 lg6>
              <v-select dense outlined style="height: 50px;" 
              id="inner_upper_type" 
              ref="inner_upper_type" 
              label="내벽상단소재" 
              v-model="inner_upper_type"
              :items="inner_upper_typeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
            </v-flex>

            <v-flex xs12 lg6 class="ml-1">
              <v-select dense outlined style="height: 50px;" 
              id="inner_lower_type" 
              ref="inner_lower_type" 
              label="내벽하부소재" 
              v-model="inner_lower_type"
              :items="inner_lower_typeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
            </v-flex>
          </v-layout>

          <v-flex xs12 lg6>
            <v-select dense outlined style="height: 50px;" 
              id="ceiling_type" 
              ref="ceiling_type" 
              label="천장소재" 
              v-model="ceiling_type"
              :items="ceiling_typeItems"
              :menu-props="{ top: false, offsetY: true }"
              item-text="name"
              return-object
              clearable
            />
          </v-flex>
          </div>
        </div>
        
          <v-layout style="height: 50px;">
            <v-flex xs12 lg6 class="mr-1">
              <v-text-field dense outlined  style="height: 50px;"
              id="ami_mac" 
              ref="ami_mac" 
              label="AMI 맥주소"  
              v-model="ami_mac" 
              @keyup="sensorMacMask(ami_mac)"
              @input="checkAmi()"
              maxlength="20"
              />
            </v-flex>

            <v-flex xs12 lg6>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined clearable
                    id="deldate" 
                    ref="deldate" 
                    v-model="deldate"
                    label="사용중지일"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title locale="ko-KR"
                  v-model="deldate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>

          <v-textarea outlined dense rows="2" name="input-7-4" 
            label="비고"
            id="history" 
            ref="history" 
            v-model="history"
            value=""
            persistent-hint :hint="hint"
          ></v-textarea>

          <v-card-actions class="d-block">
          <v-row justify= "center">
            <v-btn class="rounded-pill normal-btn ma-2 mb-5" 
              :loading= "loadingSave" 
              id="save" 
              ref="save" 
              style="height: 35px; width: 100px;" 
              type="submit" 
              @click.prevent="save()">
              저장
            </v-btn>  
            <v-btn class="rounded-pill new-btn ma-2 mb-5" 
              id="newRegister" 
              ref="newRegister" 
              style="height: 35px; width: 100px;" 
              @click="newRegister()">
              신규
            </v-btn>
          </v-row>
        </v-card-actions>
        </v-card>

        <v-card height="auto" class="dataTable ma-2" outlined color="transparent">
          <v-layout nowrap>
            <v-flex xs12 lg5>
            <v-select dense outlined rounded
                  id="company" 
                  ref="company" 
                  label="경영체" 
                  v-model="company"
                  :items="companyItems"
                  :menu-props="{ top: false, offsetY: true }"
                  no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
                  item-text="name"
                  return-object
                  @change="changeCompany()"
                  clearable
            />
            </v-flex>

            <v-flex xs12 lg5>
            <v-select dense outlined rounded class="ml-1" 
                  id="farm" 
                  ref="farm" 
                  label="사업장" 
                  v-model="farm"
                  :items="farmItems"
                  :menu-props="{ top: false, offsetY: true }"
                  no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
                  item-text="name"
                  return-object
                  @focus="comboFarms()"
                  @change="changeFarm()"  
                  clearable
            />
            </v-flex>
          </v-layout>

          <v-data-table dense height="530"
            fixed-header
            hide-default-footer
            :items-per-page= "500"
            :loading="loading"
            id="items" 
            ref="items" 
            :headers="headers"
            :items="items"
            no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
            class="elevation-3"
            @click:row="infoDetail"
            item-key="building_cd"
            single-select
            sort-by="building_name"
          >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color=#3a4f3f
                medium
                @click="childRooms(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-share-variant
              </v-icon>
            </template>
            <span class="tooltip">하위돈방/위치</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-row justify= "center" class="mx-auto ma-2 pa-2  rounded-lg">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn rounded-pill class="elevation-3 ma-3 rounded-pill normal-btn" style="width: 135px;"
          id="listBuilding" 
          ref="listBuilding" 
          @click="listBuilding()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          <span class="ml-1"> 목록고침 </span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-row>
        </v-card>
        </v-layout>
      </v-form>
    </v-card>

  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";


export default {
  name: 'Building',

  data: () => ({
    headers: [
      { text: '',align: 'center', sortable: false, value: 'actions', width: "50px", groupable: false},
      { text: '건물명', value: 'building_name', width: "130px"},
      { text: '코드', value: 'building_cd', width: "40px"},
      { text: '경영체', align: 'left', sortable: true, value: 'company_name', width: "160px",},
      { text: '사업장', value: 'farm_name', width: "120px", },
      { text: '건물분류', value: 'building_group', width: "100px", },
      // { text: '형태', value: 'building_type', sortable: false, width: "80px", class: 'green darken-4 white--text' },
      // { text: '면적(m²)', align: 'right', value: 'area', sortable: false, width: "80px",},
      // { text: '환기유형', value: 'air_type', width: "110px", class: 'green darken-4 white--text' },
      // { text: '팬유형', value: 'fan_type', width: "120px", class: 'green darken-4 white--text' },
      { text: '등록일', value: 'regdate', width: "120px",},
      { text: '사용중지일', value: 'deldate', width: "120px" },
      { text: '비고', value: 'history', width: "250px", },
    ],
    items : [],
    // company : {},
    // companyItems : [{code: "",name: ""}],
    // farm : {},
    // farmItems : [{code: "",name: ""}],

    // loading: false,
    selectedBuilding: {},
    routedBuilding: "",

    company : {},
    companyItems : [],
    farm : {},
    farmItems : [{code: "", name: ""}],

    building_group: {},
    building_groupItems : Common.comboBaseCode("BF"),

    detail_YN:"",

    building_code : "",
    building_name : "",
    building_type :  {},
    building_typeItems : Common.comboBaseCode("BT"),
    area :  "",
    air_type :  "",
    air_typeItems : Common.comboBaseCode("AT"),
    air_in :  "",
    air_inItems : Common.comboBaseCode("AI"),
    air_out :  "",
    air_outItems : Common.comboBaseCode("AO"),
    fan_type :  "",
    fan_typeItems : Common.comboBaseCode("FT"),
    
    roof_type : "",
    roof_typeItems : Common.comboBaseCode("RM"),
    roof_color : "",
    roof_colorItems : Common.comboBaseCode("CR"),
    roof_width : "",
    wall_width : "",
    
    side_upper_type : "",
    side_upper_typeItems : Common.comboBaseCode("BM"),
    side_upper_color : "",
    side_upper_colorItems : Common.comboBaseCode("CR"),
    side_lower_type : "",
    side_lower_typeItems : Common.comboBaseCode("BM"),
    end_upper_type : "",
    end_upper_typeItems : Common.comboBaseCode("BM"),
    end_upper_color : "",
    end_upper_colorItems : Common.comboBaseCode("CR"),
    end_lower_type : "",
    end_lower_typeItems : Common.comboBaseCode("BM"),
    inner_upper_type : "",
    inner_upper_typeItems : Common.comboBaseCode("BM"),
    inner_lower_type : "",
    inner_lower_typeItems : Common.comboBaseCode("BM"),
    ceiling_type : "",
    ceiling_typeItems : Common.comboBaseCode("BM"),
    deldate: "",
    history: "",
    hint : "",

    ami_mac :"",

    loading: false,
    loadingSave: false,
    
    // businessDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,

  }),
  created() {
    this.comboCompanies();
    this.comboFarms();

    // console.log("colors",this.side_upper_colorItems);
    if (this.$route.params.farm) {
        // console.log("param1",this.$route.params)
        this.company = this.$route.params.company;
        this.farm = this.$route.params.farm;
      }
    else if (!this.isNull(this.$route.params)) {
          this.routedBuilding = this.$route.params.building_cd;
    }
    this.listBuildings();
  },

  methods: {
    listBuildings() {
        if (this.isNull(this.$route.params)) this.$store.commit("resMessage","");
        this.loading = true;
        // console.log("리스트빌딩");
        this.company =  this.company || {};
        this.farm =  this.farm || {};
        Apis.listBuildings({
          company_cd: this.company && this.company.code || "",
          farm_cd: this.farm && this.farm.code || "",
          stand_date: dateUtil.format(new Date(), "yyyy-MM-dd")
        } ,(res) => {  // 정상처리
          // console.log("res",res);
          this.items = res.data;
          if (this.isNull(this.$route.params)) this.$store.commit("resMessage",res.message);
          this.loading = false;

        }).catch( (err) => {  // 개별 API 오류 처리 예제
          this.loading = false;
          console.log("listBuildings 호출 오류",err);
          this.$router.push("/");
        }) 
    }, 

    childRooms(value) {
      // alert("축사/건물/구역에 속한 돈방/위치목록으로")
      this.loadingTable = true;
      this.$router.push({
                        // path: '/farminfo/registerinfo/room',
                        name:'room',
                        params: 
                          { building_cd: value.building_cd,}
                      });
      this.loadingTable = false;
    },

    // ...mapMutations(['doLogin']),
    clearData() {
      this.company = {};
      this.farm = {};

      this.building_group = {},
      this.detail_YN = "",

      this.building_code = "";
      this.building_name = "";
      this.building_type = {};
      this.area = "";
      this.air_type = {};
      this.air_in = {};
      this.air_out = {};
      this.fan_type = {};
      this.roof_type = {};
      this.roof_color = {};
      this.roof_width = "";
      this.wall_width = "";
      this.side_upper_type = {};
      this.side_upper_color = {};
      this.side_lower_type = {};
      this.end_upper_type = {};
      this.end_upper_color = {};
      this.end_lower_type = {};
      this.inner_upper_type = {};
      this.inner_lower_type = {};
      this.ceiling_type = {};
      this.ami_mac="";
      this.deldate= "";
      this.history= "";
      this.hint = "";
    },
    checkData() {
      if (!this.company.code) {
        this.$refs.company.focus();
        return "상위 경영체를 선택입력하세요";
      }
      if (!this.farm.code) {
        this.$refs.farm.focus();
        return "상위 사업장을 선택입력하세요";
      }
      if (!this.building_name.trim()) {
        this.$refs.building_name.focus();
        return "건물명을 입력하세요";
      }
      if (!this.building_group.code) {
        this.$refs.building_group.focus();
        return "건축분류를 선택입력하세요";
      }
      if (this.building_group.code === 'BF01'){
        if (!this.building_type.code) {
          this.$refs.building_type.focus();
          return "건물형태를 선택입력하세요";
        }
      }
      if (this.building_group.code === 'BF01'){
        if (!this.detail_YN){
          this.$refs.detail_y.$el.children[1].focus()
          return "상세정보 입력을 선택하세요";
        }
    }
      if (!this.area) {
        this.$refs.area.focus();
        return "건물면적(㎡) 을 입력하세요";
      }
      return "";
    },

    checkAmi(){
      let mac = this.ami_mac.replace(/:/gi, '')
      if (mac && mac.length !== 14) {
        this.$refs.ami_mac.focus();
        return "ami 맥주소를 확인해주세요";
      }
      return "";
    },
    save() {
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      let msgAmidata = this.checkAmi(); 
      if (msgAmidata){
        this.$store.commit("resMessage",msgAmidata);
        return;
      }
      this.loadingSave = true;

      if (this.building_code) {
        // console.log("업데이트");
        Apis.updateBuildingFree({
          building_cd: this.building_code,
          company_cd: this.company.code,
          farm_cd: this.farm.code,
          building_name: this.building_name.trim(),
          building_type: this.building_type.code || "",
          building_group: this.building_group.code,
          area: Number(this.area),
          air_type: this.air_type && this.air_type.code || "",
          air_in: this.air_in && this.air_in.code || "",
          air_out: this.air_out && this.air_out.code || "",
          fan_type: this.fan_type && this.fan_type.code || "",
          roof_type: this.roof_type && this.roof_type.code || "",
          roof_color: this.roof_color && this.roof_color.code || "",
          roof_width: Number(this.roof_width),
          wall_width: Number(this.wall_width),
          side_upper_type: this.side_upper_type && this.side_upper_type.code || "",
          side_upper_color: this.side_upper_color && this.side_upper_color.code || "",
          side_lower_type: this.side_lower_type && this.side_lower_type.code || "",
          end_upper_type: this.end_upper_type && this.end_upper_type.code || "",
          end_upper_color: this.end_upper_color && this.end_upper_color.code || "",
          end_lower_type: this.end_lower_type && this.end_lower_type.code || "",
          inner_upper_type: this.inner_upper_type && this.inner_upper_type.code || "",
          inner_lower_type: this.inner_lower_type && this.inner_lower_type.code || "",
          ceiling_type: this.ceiling_type && this.ceiling_type.code || "",
          etc3 : this.ami_mac.replace(/:/gi, '') || "",
          deldate: this.deldate,
          history: this.history.trim(),
          },(res) => {  
            if (res.result) {
              this.loadingSave = false;
              this.$store.commit("resMessage",res.message);
              this.clearData();
              this.listBuildings();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        // console.log("인서트",this.company.code);
        Apis.insertBuildingOne({
          company_cd: this.company.code,
          farm_cd: this.farm.code,
          building_name: this.building_name.trim(),
          building_type: this.building_type.code || "",
          building_group: this.building_group.code,
          area: Number(this.area),
          air_type: this.air_type && this.air_type.code || "",
          air_in: this.air_in && this.air_in.code || "",
          air_out: this.air_out && this.air_out.code || "",
          fan_type: this.fan_type && this.fan_type.code || "",
          roof_type: this.roof_type && this.roof_type.code || "",
          roof_color: this.roof_color && this.roof_color.code || "",
          roof_width: Number(this.roof_width),
          wall_width: Number(this.wall_width),
          side_upper_type: this.side_upper_type && this.side_upper_type.code || "",
          side_upper_color: this.side_upper_color && this.side_upper_color.code || "",
          side_lower_type: this.side_lower_type && this.side_lower_type.code || "",
          end_upper_type: this.end_upper_type && this.end_upper_type.code || "",
          end_upper_color: this.end_upper_color && this.end_upper_color.code || "",
          end_lower_type: this.end_lower_type && this.end_lower_type.code || "",
          inner_upper_type: this.inner_upper_type && this.inner_upper_type.code || "",
          inner_lower_type: this.inner_lower_type && this.inner_lower_type.code || "",
          ceiling_type: this.ceiling_type && this.ceiling_type.code || "",
          etc3: this.ami_mac.replace(/:/gi, '') || "",
          deldate: this.deldate,
          history: this.history.trim(),
          },(res) => {  
            if (res.result) {
              this.loadingSave = false;
              this.$store.commit("resMessage",res.message);
              this.clearData();
              this.listBuildings();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      }
    },
    infoDetail(value, row) {
      row.select(true);

      this.$store.commit("resMessage","");
      this.clearData();
      // console.log("CODE =", code);
      this.loading = true;
      Apis.infoBuilding({
        building_cd: value.building_cd, 
        },(res) => {  
          if (res.result) {
            this.building_code = res.data.building_cd;
            // console.log("call data=", res.data);
            this.company = {code: res.data.company_cd.substr(0,11), name: res.data.company_cd.substr(11)};
            this.farm = {code: res.data.farm_cd.substr(0,6), name: res.data.farm_cd.substr(6)};
            this.building_name = res.data.building_name;
            this.building_type = {code: res.data.building_type.substr(0,4),name: res.data.building_type.substr(4)};
            this.building_group = {code: res.data.building_group.substr(0,4),name: res.data.building_group.substr(4)};
            this.area = res.data.area; 
            this.air_type = { code: res.data.air_type.substr(0,4), name: res.data.air_type.substr(4)}; 
            this.air_in = { code: res.data.air_in.substr(0,4), name: res.data.air_in.substr(4)}; 
            this.air_out = { code: res.data.air_out.substr(0,4), name: res.data.air_out.substr(4)}; 
            this.fan_type = { code: res.data.fan_type.substr(0,4), name: res.data.fan_type.substr(4)}; 
            this.roof_type = { code: res.data.roof_type.substr(0,4), name: res.data.roof_type.substr(4)}; 
            this.roof_color = { code: res.data.roof_color.substr(0,4), name: res.data.roof_color.substr(4), color: res.data.etc1 }; 
            this.roof_width = res.data.roof_width; 
            this.wall_width = res.data.wall_width; 
            this.side_upper_type = { code: res.data.side_upper_type.substr(0,4), name: res.data.side_upper_type.substr(4)}; 
            this.side_upper_color = { code: res.data.side_upper_color.substr(0,4), name: res.data.side_upper_color.substr(4), color: res.data.etc1 }; 
            this.side_lower_type = { code: res.data.side_lower_type.substr(0,4), name: res.data.side_lower_type.substr(4)}; 
            this.end_upper_type = { code: res.data.end_upper_type.substr(0,4), name: res.data.end_upper_type.substr(4)}; 
            this.end_upper_color = { code: res.data.end_upper_color.substr(0,4), name: res.data.end_upper_color.substr(4), color: res.data.etc2 }; 
            this.end_lower_type = { code: res.data.end_lower_type.substr(0,4), name: res.data.end_lower_type.substr(4)}; 
            this.inner_upper_type = { code: res.data.inner_upper_type.substr(0,4), name: res.data.inner_upper_type.substr(4)}; 
            this.inner_lower_type = { code: res.data.inner_lower_type.substr(0,4), name: res.data.inner_lower_type.substr(4)}; 
            this.ceiling_type = { code: res.data.ceiling_type.substr(0,4), name: res.data.ceiling_type.substr(4)}; 
            this.ami_mac = Common.getMacMask(res.data.etc3);
            this.deldate = res.data.deldate;
            this.history = res.data.history; 
            this.hint = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.loading = false;
            this.$store.commit("resMessage",res.message);
          } else {
            this.loading = false;
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            this.loading = false;
            console.log("API 호출 오류",err)
            alert(err);
        }
      ); 
    },
    newRegister() {
      this.$store.commit("resMessage","");
      this.clearData();
      this.$refs.company.focus();
      this.$store.commit("resMessage","새로 등록할 준비가 되았습니다.");
    },
    sensorMacMask(val) {
      this.ami_mac = Common.getMacMask(val)
    },
    comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    comboFarms() {
      Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 먼저 선택시 상위 콤보 동시 세팅
      if (this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
      this.listBuildings();

    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.listBuildings();
    },


  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
@media screen and (max-width: 820px){
  .flex_mobile{
    flex-wrap: wrap;
  }
  .dataTable{
    width:100% !important;
  }

}
.dataTable{
  width:50%;
}


.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
.normal-btn{
  background-color: #3a4f3f !important;
  color:white;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.new-btn{
  color:#3a4f3f !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}

</style>
