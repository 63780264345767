import { render, staticRenderFns } from "./Building.vue?vue&type=template&id=f29c7442&scoped=true"
import script from "./Building.vue?vue&type=script&lang=js"
export * from "./Building.vue?vue&type=script&lang=js"
import style0 from "./Building.vue?vue&type=style&index=0&id=f29c7442&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f29c7442",
  null
  
)

export default component.exports